.modul-kacheln {
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.modul-kachel {
    padding: 1rem;
    color: white;
    transition: height 0.4s;
    cursor: pointer;
    margin: 2px 2px !important;
}

.column-blocks-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.modul-kachel .sub-heading p {
    word-wrap: normal;
}

.modul-kachel.block p {
    margin-bottom: 0;
    padding: 0;
}

.modul-kachel .image {
    margin: 0 !important;
}

.modul-kachel .sub-heading {
    margin-top: 1rem;
}

@media screen and (max-width: 767px) {
    .modul-kachel {
        text-align: center !important;
        padding: 1rem 1rem 2rem 1rem !important;
    }
    .modul-kachel .image {
        display: flex;
        justify-content: center;
    }
}

@media screen and (min-width: 768px) and (max-width: 856px){
    .modul-kachel .image {
        max-height: 8rem;
    }
}

@media screen and (min-width: 768px) and (max-width: 990px){
    .modul-kachel .image {
        display: flex;
        justify-content: right;
    }
}


@media screen and (min-width: 990px) and (max-width: 1584px) {
    .modul-kachel .image {
        display: none !important;
    }

    .modul-kachel .sub-heading {
        height: 116px !important;
    }
}


.ui.grid.column-grid {
    margin: 1rem 0;
}
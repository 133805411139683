/* KONTAKT KACHELN ************************************************************************************/

.kontakt-kachel {
    padding: 2rem !important;
}

.kontakt-kachel h3 {
    margin-bottom: 1rem;
}

.kontakt-kachel .column-blocks-wrapper {
    padding-right: 1rem !important;
}

.kontakt-kachel a {
    color: white;
}

.kontakt-kachel a:hover {
    color: white;
}

.ui.grid {
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.beige-color .sub-heading {
    /*color: #47B531;*/
    color: black;
}

.kontakt-kachel img {
    padding: 0 !important;
    height: auto;
    width: 100%;
    margin-bottom: 1rem;
}

.email-wrapper {
    word-wrap: break-word;
    overflow-wrap: break-word;
}


@media screen and (max-width: 767px) {
    .kontakt-kachel img {
        padding: 0 !important;
        height: 6.8rem;
        width: auto;
    }

    .kontakt-kachel p {
        text-align: left;
    }
}

@media screen and (max-width: 767px) {
    .kontakt-kachel img {
        padding: 0 !important;
        height: 6.8rem;
    }

    .kontakt-kachel p {
        text-align: left !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 1150px) {
    .kontakt-kachel img {
        padding: 0 !important;
        height: auto;
    }
}

.sponsoren {
    background-color: white;
    margin: 0 !important;
    padding: 1rem;
}

.sponsor {
    padding: 1rem 1rem 0 1rem;
}